import classNames from "classnames";
import React, { Fragment, ReactNode } from "react";

const ServiceBase = ({
  title,
  children,
  bgClass,
}: {
  title: string;
  children: ReactNode;
  bgClass: string;
}) => (
  <Fragment>
    <section
      className={classNames("relative h-[280px] bg-center bg-cover flex items-center", bgClass)}
    >
      <div className="container z-[1]">
        <h1 className="text-[52px] font-bold" dangerouslySetInnerHTML={{ __html: title }}></h1>
      </div>
      <div className="absolute w-full h-full bg-[rgba(255,255,255,0.6)] top-0 left-0"></div>
    </section>
    <section>
      <div className="container py-[60px]">
        <h2 className="text-center text-[42px] font-bold mb-[60px]">
          Service <span className="text-[#F13D6F]">Features</span>
        </h2>
        {children}
      </div>
    </section>
  </Fragment>
);

export default ServiceBase;
