import { StaticImage } from "gatsby-plugin-image";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import ServiceBase from "../../components/service";

const EbookPublication = () => (
  <ServiceBase
    title={"Japanese eBook Publication Service"}
    bgClass="bg-[url('../images/services/e-book-publication/banner.png')]"
  >
    <Fragment>
      <Helmet>
        <title>Japanese eBook Publication Service | README Digital</title>
      </Helmet>
      <div className="grid md:grid-cols-2 gap-x-8 mb-[100px]">
        <StaticImage
          src="../../images/services/e-book-publication/feature-1.png"
          alt="Publish your manual docs as an eBook in Japanese"
          layout="fullWidth"
          className="mb-8"
        />
        <div>
          <h2 className="text-[36px] font-bold first-letter:text-[#F13D6F] leading-[60px] mb-[30px]">
            Publish your manual docs as an eBook in Japanese
          </h2>
          <p className="font-bold text-[18px]">
            The localized docs site built with our README Digital service can be organized, edited,
            and published as an eBook. The published eBook will greatly promote your brand awareness
            in Japan. Your eBook, 100% in the local language, will be published on Amazon, Google
            Books, and Rakuten Kobo, empowering your brand in the local market. Search "README
            Digital" on those eBook stores to find some of the successful predecessors.<br/>This service
            is available as an add-on.
          </p>
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-x-8 mb-[100px]">
        <StaticImage
          src="../../images/services/e-book-publication/feature-2.png"
          alt="PDF delivery available in higher plans"
          layout="fullWidth"
          className="order-first md:order-last mb-8"
        />
        <div>
          <h2 className="text-[36px] font-bold first-letter:text-[#F13D6F] leading-[60px] mb-[30px]">
            PDF delivery available in higher plans
          </h2>
          <p className="font-bold text-[18px] mb-4">
            In higher plans, we also provide PDF delivery service along with publishing the eBook to
            the leading stores. You will be able to utilize it for your own promotion, training, or
            any other purposes according to your own strategies. And your local partners/customers
            will love it.
          </p>
        </div>
      </div>
    </Fragment>
  </ServiceBase>
);

export default EbookPublication;
